<template>
    <userDetails page="register"></userDetails>
</template>

<script>
    import userDetails from '@/components/UserDetails'

    export default {
        name: 'Register',

        components: {
            userDetails
        },
    };
</script>
